import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useAlert } from 'react-alert';
import * as Styled from './SignIn.styles';
import Logo from '../../layouts/Logo';
import { H2 } from '../../components/Typography/Typography.styles';
import LoginForm from './steps/LoginForm';
import TwoFactorAuth from './steps/TwoFactorAuth';
import ResetPassword from './steps/ResetPassword';
import ResetPasswordDone from './steps/ResetPasswordDone';
import { userPasswordResetValidate } from '../../api/users';

const SignIn = ({ currentUser, onSignIn, tfa, setTfa }) => {
  const location = useLocation();
  const history = useHistory();
  const alert = useAlert();
  const [resetPassword, setResetPassword] = useState(0);
  const [mouseXpercentage, setMouseXpercentage] = useState(0);
  const [mouseYpercentage, setMouseYpercentage] = useState(0);

  const handleMouseMove = event => {
    setMouseXpercentage(Math.round((event.pageX / window.innerWidth) * 100));
    setMouseYpercentage(Math.round((event.pageY / window.innerHeight) * 100));
  };

  useEffect(() => {
    if (location.search)
      userPasswordResetValidate(location.search).then(response => {
        if (response.success) {
          alert.info(response.message, {
            timeout: 0
          });
        } else {
          alert.error(response.message ? response.message : 'Nieznany błąd.');
        }

        history.replace({
          search: ''
        });
      });
  }, []);

  return (
    <Styled.SignIn
      onMouseMove={event => handleMouseMove(event)}
      style={{
        background: `radial-gradient(at ${mouseXpercentage}% ${mouseYpercentage}%, #3498db, #9b59b6`
      }}
    >
      <Styled.SignInFormWrapper>
        <div className="signin-aside">
          <div className="signin-logo-wrapper">
            <Logo />
            <Logo type="client" />
          </div>
          <H2>CMS by Plej</H2>
        </div>

        <div className="signin-main">
          <div className="component-animation-wrapper">
            <CSSTransition
              in={!currentUser && resetPassword === 0}
              classNames="component-animation"
              timeout={300}
              unmountOnExit
            >
              <LoginForm
                onSignIn={onSignIn}
                currentUser={currentUser}
                setResetPassword={setResetPassword}
              />
            </CSSTransition>
          </div>

          <div className="component-animation-wrapper">
            <CSSTransition
              in={currentUser && !tfa && resetPassword === 0}
              classNames="component-animation"
              timeout={300}
              unmountOnExit
            >
              <TwoFactorAuth setTfa={setTfa} currentUser={currentUser} />
            </CSSTransition>
          </div>
        </div>
      </Styled.SignInFormWrapper>
    </Styled.SignIn>
  );
};

export default SignIn;
