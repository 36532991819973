import React from 'react';
import { Formik, Form } from 'formik';
import { useAlert } from 'react-alert';
import * as Yup from 'yup';
import Button from '../../components/Button';
import InputWrapper from '../../components/InputWrapper';
import { userPasswordChange } from '../../api/users';
import { messages } from '../../helpers/forms';

const AccountPasswordChange = ({ currentUser }) => {
  const alert = useAlert();

  const onUpdatePassword = (oldPassword, newPassword) => {
    userPasswordChange(currentUser._id, oldPassword, newPassword)
      .then(response => {
        if (response.success) {
          alert.info(response.message);
          currentUser.logout();
        } else {
          alert.error(response.message);
        }
      })
      .catch(error => console.error('Error loading data', error));
  };

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: ''
      }}
      validationSchema={Yup.object({
        oldPassword: Yup.string().required(messages.required),
        newPassword: Yup.string().required(messages.required)
      })}
      onSubmit={values =>
        onUpdatePassword(values.oldPassword, values.newPassword)
      }
    >
      {({ values, errors, touched }) => (
        <Form noValidate>
          <InputWrapper
            name="oldPassword"
            type="password"
            label="Stare hasło"
            errors={errors}
            touched={touched}
            values={values}
          />
          <InputWrapper
            name="newPassword"
            type="password"
            label="Nowe hasło"
            errors={errors}
            touched={touched}
            values={values}
          />
          <Button primary>Zapisz</Button>
        </Form>
      )}
    </Formik>
  );
};

export default AccountPasswordChange;
