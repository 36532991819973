import styled from 'styled-components';

const bulletStyles = `
  position: absolute;
  bottom: calc(100% + 5px);
  left: 5px;
  font-size: 26px; 
  line-height: 0.8;
`;

const TopPanel = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  gap: 16px;
  z-index: 99;
  width: 100%;
  background-color: #ebf1f5;
  padding: 8px 0;
`;

const PriceItemsList = styled.ul`
  counter-reset: rows;
`;

const PriceItem = styled.li`
  counter-increment: rows;
  position: relative;
  margin: 40px 0 20px;
  padding: 20px;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colors.lightGray};

  &::before {
    content: counter(rows);
    ${bulletStyles};
    color: ${props => props.theme.colors.darkenGray};
  }
`;

const PriceItemsSublist = styled.ul`
  counter-reset: items;
`;

const PriceSubitem = styled.li`
  counter-increment: items;
  position: relative;
  margin: 40px 0 20px;
  padding: 20px;
  border-radius: ${props => props.theme.borderRadius};
  background: #fff;

  &::before {
    content: counter(rows) '.' counter(items);
    ${bulletStyles};
    color: ${props => props.theme.colors.darkenGray};
  }
`;

export { PriceItemsList, PriceItem, PriceItemsSublist, PriceSubitem, TopPanel };
