import React from 'react';
import * as Styled from './NavToggler.styles';

const NavToggler = ({ navToggler }) => {
  return (
    <Styled.NavToggler
      onClick={navToggler.toggle}
      className={navToggler.navOpened ? 'active' : ''}
    >
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
    </Styled.NavToggler>
  );
};

export default NavToggler;
