import styled from 'styled-components';

const TopPanel = styled.div`
  display: flex;
  gap: 16px;
  z-index: 99;
  padding: 8px 0;
`;

const Articles = styled.article`
  width: 100%;

  table {
    .application-description {
      min-width: 250px;
    }

    td {
      min-width: 80px;

      img {
        height: 150px;
      }
    }

    .action {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export { Articles, TopPanel };
