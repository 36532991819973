import styled, { css } from 'styled-components';

const RowElement = styled.div`
  flex: 1;
`;

const RowWrapper = styled.div`
  ${props => css`
    @media (min-width: ${props.theme.breakpoints.lgUp}) {
      display: flex;
      gap: 16px;
    }
  `}
`;

export { RowWrapper, RowElement };
