import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import Layout from '../../layouts/Layout/Layout';
import Box from '../../layouts/Box';
import Table from '../../components/Typography/Table';
import SignUpForm from './SignUpForm';
import * as Styled from './Users.styles';
import { getUsers, removeUser } from '../../api/users';
import Button from '../../components/Button';

const Users = () => {
  const alert = useAlert();
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    getUsers()
      .then(response => setUsersList(response))
      .catch(error => console.error('Error loading users data', error));
  }, []);

  const onRemove = id =>
    removeUser(id)
      .then(() => setUsersList(usersList.filter(user => user._id !== id)))
      .catch(error => alert.error('Wystąpił błąd', error));

  return (
    <Layout title="Użytkownicy" contentClass="main-user-account">
      <Styled.Users>
        <Box header="Lista użytkowników" halfLarge>
          <Table>
            <thead>
              <tr>
                <th>Imie i nazwisko</th>
                <th>E-mail</th>
                <th>Typ konta</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {usersList.map(user => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>Administrator</td>
                  <td>
                    <Button
                      danger
                      onlyIcon
                      small
                      onClick={() =>
                        // eslint-disable-next-line no-undef,no-alert
                        window.confirm('Na pewno chcesz usunąć użytkownika?') &&
                        onRemove(user._id)
                      }
                    >
                      Usuń
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        <Box header="Utwórz nowego użytkownika" halfLarge>
          <SignUpForm usersList={usersList} setUsersList={setUsersList} />
        </Box>
      </Styled.Users>
    </Layout>
  );
};

export default Users;
