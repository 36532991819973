import styled from 'styled-components';

const Editor = styled.div`
  margin: 14px 0;

  .article {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 999;
  }
`;

const Label = styled.div`
  margin-bottom: 8px;
`;

export { Editor, Label };
