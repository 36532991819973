import api, { setToken } from './init';
import { getDecodedToken } from './token';

// Sends a POST request to /auth/sign-up on the server, with first name, last name, email & password registering the user and returning the JWT
export const signUp = ({ name, email, password }) => {
  return api
    .post('/auth/sign-up', { name, email, password })
    .then(res => res.data);
};

// Sends a POST request to /auth on the server, with the email & password returning the JWT
// Belonging to the user with supplied credentials
export const signIn = ({ email, password }) => {
  return api
    .post('/auth', { email, password })
    .then(res => {
      const { token } = res.data;
      setToken(token);
      return getDecodedToken();
    })
    .catch(res =>
      res.response.status === 400 || res.response.status === 401
        ? 'Błędny login lub hasło.'
        : 'Nieznany błąd. Proszę skontaktować się z administratorem.'
    );
};

export const signOut = () => {
  setToken(null);
};

export const verifyTfa = ({ email, code }) => {
  return api
    .post('/auth/tfa', { email, code })
    .then(res => res)
    .catch(res => res);
};

export const verifyJWTToken = () => {
  return api
    .post('/auth/check-token')
    .then(res => res.data)
    .catch(res => res);
};
