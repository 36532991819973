import api from './init';

export const getArticles = data => {
  return api
    .get('/articles/all', {
      params: data,
      headers: {
        secret: process.env.REACT_APP_API_SECRET_KEY
      }
    })
    .then(res => res.data);
};

export const getArticle = id => {
  return api.get(`/articles/${id}`).then(res => res.data);
};

export const removeArticle = id => {
  return api.delete(`/articles/${id}`).then(res => res.data);
};

export const addArticle = data => {
  return api.post(`/articles/add`, data).then(res => res.data);
};

export const updateArticle = (id, data) => {
  return api.post(`/articles/${id}/update`, data).then(res => res.data);
};

export const updateArticlePublished = (id, published) => {
  return api
    .post(`/articles/${id}/update/published`, published)
    .then(res => res.data);
};
