export const messages = {
  required: 'To pole jest wymagane.',
  email: 'Niepoprawny adres email.'
};

export const publishedStatuses = [
  {
    value: true,
    label: 'Opublikowane'
  },
  {
    value: false,
    label: 'Nieopublikowane'
  }
];

export const languages = [
  {
    value: 'pl',
    label: 'Polski'
  },
  {
    value: 'en',
    label: 'Angielski'
  }
];
