import React from 'react';
import * as Styled from './RowWrapper.styles';

const RowWrapper = ({ children }) => (
  <Styled.RowWrapper>
    {children.map((el, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Styled.RowElement key={`row-element-${idx}`}>{el}</Styled.RowElement>
    ))}
  </Styled.RowWrapper>
);

export default RowWrapper;
