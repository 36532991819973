import { NavLink as NativeNavLink } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/Button';
import Icon from '../../components/Icon/Icon';

const Navigation = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  right: 0;
  padding: 20px 15px;
  z-index: 100;
  visibility: hidden;
  width: 100%;
  max-width: 220px;
  height: 100%;
  background: #333;
  color: ${props => props.theme.colors.navLink};
  transition: all 0.5s;
  opacity: 1;
  transform: translateX(100%);

  &.nav-expanded {
    visibility: visible;
    transition: transform 0.5s;
    transform: translateX(0);
  }
`;

const NavList = styled.ul`
  position: sticky;
`;

const NavItem = styled.li`
  > p {
    margin-top: 20px;
  }
`;

const NavLink = styled(NativeNavLink)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.navLink};
  padding-bottom: 30px;

  &.active,
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.white};
  }

  &.active {
    &:focus,
    &:hover {
      color: ${props => props.theme.colors.darkenGray};
    }
  }
`;

const NavIcon = styled(Icon)`
  margin-right: 10px;
  fill: currentColor;
  display: block;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 22px;
    height: 22px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    width: 26px;
    height: 26px;
  }
`;

const NavUserIcon = styled(Icon)`
  margin-right: 12px;
  color: ${props => props.theme.colors.white};
`;

const NavUserBox = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;

const NavUserText = styled.span`
  > span {
    color: ${props => props.theme.colors.white};
  }
  > small {
    display: block;
    font-size: 80%;
  }
`;

const NavLogoutBtn = styled(Button)`
  font-weight: 400;
  font-size: 16px;
  text-transform: initial;
  margin-top: 10px;
  color: ${props => props.theme.colors.navLink};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.white};
    background-color: transparent;
  }
`;

export {
  Navigation,
  NavList,
  NavItem,
  NavLink,
  NavIcon,
  NavUserIcon,
  NavUserBox,
  NavUserText,
  NavLogoutBtn
};
