import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useAlert } from 'react-alert';
import Button from '../../components/Button';
import InputWrapper from '../../components/InputWrapper';
import { messages } from '../../helpers/forms';
import { signUp } from '../../api/auth';

const SignUpForm = ({ usersList, setUsersList }) => {
  const alert = useAlert();

  const generateRandomPassword = () => {
    return Math.random()
      .toString(36)
      .substring(2, 15);
  };

  // Pass supplied first name, lastname, email & password to the signUp function, returns the user's token
  const onSignUp = ({ name, email, password }, setSubmitting, resetForm) => {
    signUp({ name, email, password }).then(response => {
      if (response.token) {
        setUsersList([...usersList, { name, email, password }]);

        alert.info(`Dodano nowego użytkownika. \n2FA token: ${response.tfa}`, {
          timeout: 0
        });

        setSubmitting(false);
        resetForm({
          values: { name: '', email: '', password: generateRandomPassword() }
        });
      } else {
        alert.error(response.message);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        password: generateRandomPassword()
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(messages.required),
        email: Yup.string().required(messages.required),
        password: Yup.string().required(messages.required)
      })}
      onSubmit={(values, { setSubmitting, resetForm }) =>
        onSignUp(values, setSubmitting, resetForm)
      }
    >
      {({ values, errors, touched }) => (
        <Form noValidate>
          <InputWrapper
            name="name"
            type="text"
            label="Imię i nazwisko"
            errors={errors}
            touched={touched}
            values={values}
            autocomplete="off"
          />
          <InputWrapper
            name="email"
            type="email"
            label="Adres e-mail"
            errors={errors}
            touched={touched}
            values={values}
            autocomplete="off"
          />
          <InputWrapper
            name="password"
            type="text"
            label="Hasło"
            errors={errors}
            touched={touched}
            values={values}
            autocomplete="off"
          />
          <Button primary>Załóż konto</Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
