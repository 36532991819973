import React from 'react';
import * as Styled from './Navigation.styles';

const Navigation = ({ currentUser, navToggler }) => (
  <Styled.Navigation
    className={`${navToggler.navOpened ? 'nav-expanded' : ''}`}
  >
    <Styled.NavUserBox>
      <Styled.NavUserIcon icon="user-rounded" size={32} />
      <Styled.NavUserText>
        <span>{currentUser.name}</span>
        <small>Użytkownik zalogowany</small>
      </Styled.NavUserText>
    </Styled.NavUserBox>
    <Styled.NavList>
      <Styled.NavItem>
        <Styled.NavLink to="/">
          <Styled.NavIcon icon="attendance" removeInlineStyle />
          Artykuły
        </Styled.NavLink>
      </Styled.NavItem>
      <Styled.NavItem>
        <Styled.NavLink to="/price-lists">
          <Styled.NavIcon icon="attendance" removeInlineStyle />
          Cennik
        </Styled.NavLink>
      </Styled.NavItem>
      <Styled.NavItem>
        <Styled.NavLink to="/submissions">
          <Styled.NavIcon icon="attendance" removeInlineStyle />
          Zgłoszenia z formularza
        </Styled.NavLink>
      </Styled.NavItem>
      <Styled.NavItem>
        <Styled.NavLink to="/clients">
          <Styled.NavIcon icon="attendance" removeInlineStyle />
          Klienci
        </Styled.NavLink>
      </Styled.NavItem>
      {currentUser && (
        <Styled.NavItem>
          <Styled.NavLink to="/users">
            <Styled.NavIcon icon="team" removeInlineStyle />
            Użytkownicy
          </Styled.NavLink>
        </Styled.NavItem>
      )}
      {currentUser && (
        <Styled.NavItem>
          <Styled.NavLink to="/account">
            <Styled.NavIcon icon="user" removeInlineStyle />
            Twoje konto
          </Styled.NavLink>
        </Styled.NavItem>
      )}
    </Styled.NavList>
    <Styled.NavLogoutBtn onClick={currentUser.logout} asLink>
      <Styled.NavIcon icon="unlock" removeInlineStyle />
      Wyloguj się
    </Styled.NavLogoutBtn>
  </Styled.Navigation>
);

export default Navigation;
