import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const OnRouteChange = ({ history, navToggler, mainWrapperRef }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      navToggler.close();

      if (mainWrapperRef.current)
        mainWrapperRef.current.scroll({
          top: 0,
          behavior: 'smooth'
        });
    });
    return () => unlisten();
  }, []);
  return null;
};

export default withRouter(OnRouteChange);
