import styled, { css } from 'styled-components';
import theme from '../../config/theme';

const SwitchWrapper = styled.button`
  padding: 2px;
  width: 56px;
  box-sizing: border-box;
  border: 2px solid ${theme.base.colors.initialGray};
  border-radius: 48px;
  background-color: ${theme.base.colors.lightGray};
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          border-color: ${theme.base.colors.success};
        `
      : ''}
`;

const Circle = styled.div`
  display: block;
  width: 24px;
  aspect-ratio: 1;
  background-color: ${theme.base.colors.semiGray};
  border-radius: 48px;
  transform: translateX(0);
  transition: transform 0.3s ease-in;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${theme.base.colors.successFocus};
          transform: translateX(24px);
        `
      : ''}
`;

export { SwitchWrapper, Circle };
