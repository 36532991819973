import React from 'react';
import { useAlert } from 'react-alert';
import Table from '../../components/Typography/Table';
import Button from '../../components/Button';
import * as Styled from '../../components/Typography/Table/Table.styles';
import { removeSubmission } from '../../api/submissions';
import { renderDate } from '../../helpers/date';

const SubmissionsTable = ({ submissionsData, setSubmissionsData }) => {
  const alert = useAlert();

  const onRemove = id =>
    removeSubmission(id)
      .then(() =>
        setSubmissionsData(submissionsData.filter(item => item._id !== id))
      )
      .catch(error => alert.error('Wystąpił błąd', error));

  return (
    <Styled.TableWrapper>
      <Table>
        <thead>
          <tr>
            <th>E-mail zgłaszającego</th>
            <th>Numer zgłaszającego</th>
            <th>Data zgłoszenia</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {submissionsData &&
            submissionsData.map(row => (
              <tr key={row._id}>
                <td>{row.email || ''}</td>
                <td>{row.phoneNumber || ''}</td>
                <td>{renderDate(row.addedDate)}</td>
                <td>
                  <Button
                    danger
                    small
                    onlyIcon
                    onClick={() =>
                      // eslint-disable-next-line no-undef,no-alert
                      window.confirm('Na pewno chcesz usunąć zgłoszenie?') &&
                      onRemove(row._id)
                    }
                  >
                    Usuń
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Styled.TableWrapper>
  );
};

export default SubmissionsTable;
