import React from 'react';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Table from '../../components/Typography/Table';
import Button from '../../components/Button';
import * as Styled from '../../components/Typography/Table/Table.styles';
import { removeSinglePriceList } from '../../api/price-lists';

const PriceListsTable = ({ priceListsData, setPriceListsData }) => {
  const alert = useAlert();

  const onRemove = id =>
    removeSinglePriceList(id)
      .then(() =>
        setPriceListsData(priceListsData.filter(item => item._id !== id))
      )
      .catch(error => alert.error('Wystąpił błąd', error));

  return (
    <Styled.TableWrapper>
      <Table>
        <thead>
          <tr>
            <th>Tytuł</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {priceListsData &&
            priceListsData.map(row => (
              <tr key={row._id}>
                <td>
                  <Link to={`/price-lists/edit/${row._id}`}>{row.title}</Link>
                </td>
                <td>
                  <Button
                    type="button"
                    small
                    primary
                    as={Link}
                    to={`/price-lists/edit/${row._id}`}
                  >
                    Edytuj
                  </Button>
                </td>
                <td>
                  <Button
                    danger
                    onlyIcon
                    small
                    onClick={() =>
                      // eslint-disable-next-line no-undef,no-alert
                      window.confirm(
                        'Na pewno chcesz usunąć cennik wraz z pozycjami?'
                      ) && onRemove(row._id)
                    }
                  >
                    Usuń
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Styled.TableWrapper>
  );
};

export default PriceListsTable;
