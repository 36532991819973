import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useAlert } from 'react-alert';
import { useParams, useHistory, Link } from 'react-router-dom';
import Button from '../../components/Button';
import InputWrapper from '../../components/InputWrapper';
import { messages } from '../../helpers/forms';
import { getClient, updateClient, addClient } from '../../api/client';
import Layout from '../../layouts/Layout/Layout';
import Box from '../../layouts/Box';
import Editor from '../../components/Editor';
import * as Styled from './Clients.styles';

const AddClient = () => {
  const alert = useAlert();
  const { id } = useParams();
  const history = useHistory();
  const isEdit = id !== undefined;

  const [currentValues, setCurrentValues] = useState({
    title: '',
    desc: '',
    descEn: ''
  });

  useEffect(() => {
    if (isEdit) {
      getClient(id)
        .then(response => {
          setCurrentValues({
            title: response.title,
            desc: response.desc,
            descEn: response.descEn || ''
          });
        })
        .catch(error => console.log(error));
    }
  }, []);

  const onCreateEntry = ({ title, desc, descEn }, setSubmitting, resetForm) => {
    if (isEdit) {
      updateClient(id, {
        title,
        desc,
        descEn
      })
        .then(() => {
          alert.info(`Klient został zaktualizowany`);
          setSubmitting(false);
          resetForm();
          history.push('/clients');
        })
        .catch(error => alert.error(error));
    } else {
      addClient({
        title,
        desc,
        descEn
      })
        .then(() => {
          alert.info(`Dodano nowego klienta.`);
          setSubmitting(false);
          resetForm();
          history.push('/clients');
        })
        .catch(error => alert.error(error));
    }
  };

  return (
    <Layout title={isEdit ? 'Aktualizuj klienta' : 'Dodaj klienta'}>
      <Styled.TopPanel>
        <Button as={Link} to="/" primary>
          &lt; Powrót
        </Button>
      </Styled.TopPanel>
      <Box>
        <Formik
          initialValues={{
            title: currentValues.title,
            desc: currentValues.desc
          }}
          enableReinitialize
          validationSchema={Yup.object({
            title: Yup.string().required(messages.required),
            desc: Yup.string().required(messages.required)
          })}
          onSubmit={(formValues, { setSubmitting, resetForm }) => {
            onCreateEntry(formValues, setSubmitting, resetForm);
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form noValidate>
              <InputWrapper
                name="title"
                type="text"
                label="Tytuł"
                errors={errors}
                touched={touched}
                values={values}
              />

              <Editor
                label="Opis"
                data={values.desc}
                onChange={value => setFieldValue('desc', value)}
                id="client-desc"
              />

              <Editor
                label="Opis Angielski"
                data={values.descEn}
                onChange={value => setFieldValue('descEn', value)}
                id="client-descen"
              />

              <Button type="submit" success>
                {isEdit ? 'Aktualizuj' : 'Dodaj'}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Layout>
  );
};

export default AddClient;
