import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useAlert } from 'react-alert';
import { useParams, useHistory, Link } from 'react-router-dom';
import { TagsInput } from 'react-tag-input-component';
import Button from '../../components/Button';
import InputWrapper from '../../components/InputWrapper';
import { messages } from '../../helpers/forms';
import { addArticle, getArticle, updateArticle } from '../../api/article';
import Layout from '../../layouts/Layout/Layout';
import Box from '../../layouts/Box';
import Editor from '../../components/Editor';
import RowWrapper from '../../components/RowWrapper/RowWrapper';
import * as Styled from './Articles.styles';

const AddArticle = ({ currentUser }) => {
  const alert = useAlert();
  const { id } = useParams();
  const history = useHistory();
  const isEdit = id !== undefined;

  const [currentValues, setCurrentValues] = useState({
    title: '',
    shortDesc: '',
    slug: '',
    external: false,
    link: '',
    content: '',
    published: false,
    category: '',
    author: '',
    tags: ['artykuł'],
    youtubeLink: '',
    spotifyLink: '',
    appleLink: '',
    googlePodcastsLink: ''
  });

  useEffect(() => {
    if (isEdit) {
      getArticle(id)
        .then(response => {
          setCurrentValues({
            title: response.title,
            slug: response.slug,
            shortDesc: response.shortDesc,
            external: response.external,
            link: response.link,
            content: response.content,
            published: response.published,
            category: response.category,
            tags: response.tags,
            author: response.author,
            youtubeLink: response.youtubeLink,
            spotifyLink: response.spotifyLink,
            appleLink: response.appleLink,
            googlePodcastsLink: response.googlePodcastsLink
          });
        })
        .catch(error => console.log(error));
    }
  }, []);

  const onCreateEntry = (
    {
      title,
      slug,
      shortDesc,
      external,
      link,
      content,
      published,
      category,
      tags,
      author,
      youtubeLink,
      spotifyLink,
      appleLink,
      googlePodcastsLink
    },
    setSubmitting,
    resetForm
  ) => {
    if (isEdit) {
      updateArticle(id, {
        title,
        slug,
        shortDesc,
        external,
        link,
        content,
        published,
        category,
        tags,
        author: author || currentUser.name,
        youtubeLink,
        spotifyLink,
        appleLink,
        googlePodcastsLink
      })
        .then(() => {
          alert.info(`Artykuł został zaktualizowany`);
          setSubmitting(false);
          resetForm();
          history.push('/');
        })
        .catch(error => alert.error(error));
    } else {
      addArticle({
        title,
        slug,
        shortDesc,
        external,
        link,
        content,
        published,
        category,
        tags,
        author: author || currentUser.name,
        youtubeLink,
        spotifyLink,
        appleLink,
        googlePodcastsLink
      })
        .then(() => {
          alert.info(`Dodano nowy artykuł.`);
          setSubmitting(false);
          resetForm();
          history.push('/');
        })
        .catch(error => alert.error(error));
    }
  };

  return (
    <Layout title={isEdit ? 'Aktualizuj artykuł' : 'Dodaj artykuł'}>
      <Styled.TopPanel>
        <Button as={Link} to="/" primary>
          &lt; Powrót
        </Button>
      </Styled.TopPanel>
      <Box>
        <Formik
          initialValues={{
            title: currentValues.title,
            slug: currentValues.slug,
            shortDesc: currentValues.shortDesc,
            external: currentValues.external,
            link: currentValues.link,
            content: currentValues.content,
            published: currentValues.published,
            category: currentValues.category,
            tags: currentValues.tags,
            author: currentValues.author,
            youtubeLink: currentValues.youtubeLink,
            spotifyLink: currentValues.spotifyLink,
            appleLink: currentValues.appleLink,
            googlePodcastsLink: currentValues.googlePodcastsLink
          }}
          enableReinitialize
          validationSchema={Yup.object({
            title: Yup.string().required(messages.required),
            shortDesc: Yup.string().required(messages.required),
            category: Yup.string().required(messages.required)
          })}
          onSubmit={(formValues, { setSubmitting, resetForm }) => {
            onCreateEntry(formValues, setSubmitting, resetForm);
          }}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form noValidate>
              <InputWrapper
                name="title"
                type="text"
                label="Tytuł artykułu"
                errors={errors}
                touched={touched}
                values={values}
              />

              {(values.slug !== '' || isEdit) && (
                <InputWrapper
                  name="slug"
                  type="text"
                  label="URL artykułu (slug)"
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              )}

              <InputWrapper
                name="shortDesc"
                type="text"
                label="Krótki opis"
                errors={errors}
                touched={touched}
                values={values}
              />

              <InputWrapper
                name="author"
                type="text"
                label="Autor wpisu"
                errors={errors}
                touched={touched}
                values={values}
              />

              <InputWrapper
                name="youtubeLink"
                type="text"
                label="Link YouTube"
                errors={errors}
                touched={touched}
                values={values}
              />

              <InputWrapper
                name="spotifyLink"
                type="text"
                label="Link Spotify"
                errors={errors}
                touched={touched}
                values={values}
              />

              <InputWrapper
                name="appleLink"
                type="text"
                label="Link Apple Music"
                errors={errors}
                touched={touched}
                values={values}
              />

              <InputWrapper
                name="googlePodcastsLink"
                type="text"
                label="Link Google Podcasts"
                errors={errors}
                touched={touched}
                values={values}
              />

              <RowWrapper>
                <InputWrapper
                  name="external"
                  label="Typ artykułu"
                  as="select"
                  errors={errors}
                  touched={touched}
                  values={values}
                >
                  <option value="false">Wewnętrzny</option>
                  <option value="true">Zewnętrzny</option>
                </InputWrapper>
                <InputWrapper
                  name="published"
                  label="Status artykułu"
                  as="select"
                  errors={errors}
                  touched={touched}
                  values={values}
                >
                  <option value="true">Opublikowany</option>
                  <option value="false">Nieopublikowany</option>
                </InputWrapper>
                <InputWrapper
                  name="category"
                  label="Kategoria"
                  as="select"
                  errors={errors}
                  touched={touched}
                  values={values}
                >
                  <option value="">Wybierz kategorie</option>
                  <option value="articles">Artykuły</option>
                  <option value="podcasts">Podcasty</option>
                </InputWrapper>
              </RowWrapper>

              {values.external.toString() === 'true' ? (
                <InputWrapper
                  name="link"
                  type="text"
                  label="Link zewnętrzny"
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              ) : (
                <>
                  <TagsInput
                    value={values.tags}
                    onChange={value => setFieldValue('tags', value)}
                    name="tags"
                    placeHolder="Tagi artykułu"
                  />
                  <br />
                  <Editor
                    label="Treść artykułu"
                    data={values.content}
                    onChange={value => setFieldValue('content', value)}
                    id="article-content"
                    className="article"
                  />
                </>
              )}
              <Button type="submit" success>
                {isEdit ? 'Aktualizuj' : 'Dodaj'}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Layout>
  );
};

export default AddArticle;
