import React from 'react';
import Select from 'react-select';
import * as Styled from './SelectWrapper.styles';

const SelectWrapper = ({
  value,
  handleChange,
  options,
  defaultValue,
  placeholder,
  isSearchable
}) => {
  const onChange = selectedOption => {
    handleChange(selectedOption.value);
  };

  return (
    <Styled.SelectWrapper>
      <Select
        value={value}
        onChange={onChange}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isSearchable={isSearchable}
        classNamePrefix="select-wrapper"
      />
    </Styled.SelectWrapper>
  );
};

export default SelectWrapper;
