import styled from 'styled-components';

const MainWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  transition: 0.5s;
  will-change: transform, border-radius;
  transform: scale(1) translateX(0);

  &.nav-expanded {
    border-radius: ${props => props.theme.borderRadius};
    overflow: hidden;
    transform: scale(0.85) translateX(-230px);
  }
`;

const MainWrapperOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 1000%;
  width: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0.25);
  transform: translate3d(0, 0, 0);
`;

export { MainWrapper, MainWrapperOverlay };
