import { createGlobalStyle } from 'styled-components';
import fontLight from '../assets/fonts/OpenSans-Light.woff2';
import fontRegular from '../assets/fonts/OpenSans-Regular.woff2';
import fontSemiBold from '../assets/fonts/OpenSans-SemiBold.woff2';
import fontBold from '../assets/fonts/OpenSans-Bold.woff2';

const GlobalStyle = createGlobalStyle`
 
  /* Reset
  ------------------------------------------------------------------------*/
  
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  main, article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  
  body {
    line-height: 1;
  }
  
  ol, ul {
    list-style: none;
  }
  
  blockquote, q {
    quotes: none;
  }
  
  blockquote:before,
  blockquote:after,
  q:before, q:after {
    content: "";
    content: none;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  // IE placeholder input clear ['X'] hide
  ::-ms-clear {
      display: none;
  }
  
  li {
    list-style: none;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  p {
    margin-bottom: 15px;
  }
  
  /* Abrr */
  abbr[title],
  abbr[data-original-title] {
    border: 0;
  }
  
  /* Global
  ------------------------------------------------------------------------*/
  
  @font-face {
    font-family: 'Open Sans';
    src: local(''), url(${fontLight}) format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Open Sans';
    src: local(''), url(${fontRegular}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }


  @font-face {
    font-family: 'Open Sans';
    src: local(''), url(${fontSemiBold}) format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: local(''), url(${fontBold}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }
 
  *,
  *:before, 
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }  
  
  body, html, #app, #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  body {
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.fontColor};
    line-height: 1.3;
    text-align: left;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }
  
  /* Anchors, Buttons */
  a {
    color: ${props => props.theme.colors.primary};
    
    &:hover,
    &:focus {
      color: ${props => props.theme.colors.primaryDarken};
    }
  }
  
  a, button {
    cursor: pointer;
    text-decoration: none;
    transition: all .2s;
    border: 0;
    outline: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
  }
  
  .no-wrap, .nowrap {
	  white-space: nowrap;
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  
  .component-animation-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .component-animation-enter {
    opacity: 0;
    transform: translateY(5%);
    transition: opacity .3s ease-out, transform .3s ease-out;
    
    &.component-animation-enter-active {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .component-animation-exit {
    position: absolute;
    left: 0;
    top: initial;
    right: 0;
    opacity: 1;
    transform: translateY(0);
    transition: opacity .3s ease-out, transform .3s ease-out;
    
    &.component-animation-exit-active {
      opacity: 0;
      transform: translateY(5%);
    }
  }
`;

export default GlobalStyle;
