import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Users from './Users';
import Articles from './Articles';
import ErrorPage from './ErrorPage';
import Account from './Account';
import PriceLists from './PriceLists';
import AddArticle from './Articles/AddArticle';
import AddPriceList from './PriceLists/AddPriceList';
import Submissions from './Submissions';
import Clients from './Clients/Clients';
import AddClient from './Clients/AddClient';

const Routes = ({ currentUser }) => {
  const location = useLocation();

  return (
    <TransitionGroup className="view-animation-wrapper">
      <CSSTransition
        timeout={300}
        classNames="view-animation"
        key={location.key}
      >
        <Switch location={location}>
          <Route path="/" exact component={Articles} />

          <Route
            path="/articles/add"
            render={() => <AddArticle currentUser={currentUser} />}
          />

          <Route
            path="/articles/edit/:id"
            render={() => <AddArticle currentUser={currentUser} />}
          />

          <Route path="/price-lists" exact component={PriceLists} />
          <Route path="/price-lists/add" component={AddPriceList} />
          <Route path="/price-lists/edit/:id" component={AddPriceList} />
          <Route path="/submissions" component={Submissions} />

          <Route path="/clients" exact component={Clients} />
          <Route path="/clients/add" component={AddClient} />
          <Route path="/clients/edit/:id" component={AddClient} />

          <Route path="/users" component={Users} />
          <Route
            path="/account"
            render={() => <Account currentUser={currentUser} />}
          />

          <Route component={ErrorPage} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Routes;
