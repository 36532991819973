import React, { useState, useEffect, useRef } from 'react';
import { useAlert } from 'react-alert';
import { useIdleTimer } from 'react-idle-timer';
import OnRouteChange from './layouts/OnRouteChange';
import { HtmlHead } from './layouts/HtmlHead/HtmlHead';
import Navigation from './layouts/Navigation';
import MainWrapper from './layouts/MainWrapper';
import Header from './layouts/Header';
import Main from './layouts/Main';
import PageWrapper from './layouts/PageWrapper';
import Footer from './layouts/Footer';
import Routes from './pages/Routes';
import SignIn from './pages/SignIn';
import { getDecodedToken } from './api/token';
import { signIn, signOut, verifyJWTToken } from './api/auth';

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [tfa, setTfa] = useState(null);
  const [navOpened, setNavOpened] = useState(false);
  const alert = useAlert();
  const mainWrapperRef = useRef(null);

  const onSignOut = () => {
    signOut();
    setCurrentUser(null);
    setTfa(null);
    setNavOpened(false);
  };

  useEffect(() => {
    verifyJWTToken().then(response => {
      if (!response.success) return;

      const decodedToken = getDecodedToken();

      if (decodedToken) {
        setCurrentUser({
          name: decodedToken.name,
          email: decodedToken.email,
          _id: decodedToken.sub,
          logout: onSignOut
        });

        setTfa(true);
      }
    });
  }, []);

  const onSignIn = ({ email, password }) => {
    signIn({ email, password }).then(response => {
      if (response.email) {
        setCurrentUser({
          name: response.name,
          email: response.email,
          _id: response.sub,
          logout: onSignOut
        });
      } else {
        alert.error(response);
      }
    });
  };

  const navToggler = {
    navOpened,
    toggle: () => setNavOpened(!navOpened),
    close: () => setNavOpened(false)
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 min
    onIdle: () => onSignOut,
    debounce: 500
  });

  return (
    <div id="app" className="App">
      <HtmlHead />
      <OnRouteChange navToggler={navToggler} mainWrapperRef={mainWrapperRef} />

      <PageWrapper>
        {!tfa && (
          <SignIn
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            onSignIn={onSignIn}
            tfa={tfa}
            setTfa={setTfa}
          />
        )}

        {currentUser && tfa && (
          <>
            <Navigation currentUser={currentUser} navToggler={navToggler} />
            <MainWrapper
              navToggler={navToggler}
              closeClick={navToggler.close}
              mainWrapperRef={mainWrapperRef}
            >
              <Header navToggler={navToggler} />
              <Main>
                <Routes
                  currentUser={currentUser}
                  setCurrentUser={setCurrentUser}
                  onSignIn={onSignIn}
                />
              </Main>
              <Footer />
            </MainWrapper>
          </>
        )}
      </PageWrapper>
    </div>
  );
};

export default App;
