import React from 'react';
import * as Styled from './Logo.styles';
import logo from '../../assets/logo.png';
import client from '../../assets/client.png';

const Logo = props => {
  const { anchor, type } = props;
  const image = <img src={type === 'client' ? client : logo} alt="Plej" />;

  if (type === 'client')
    return <Styled.LogoPartner>{image}</Styled.LogoPartner>;

  return (
    <Styled.Logo>
      {!anchor && image}
      {anchor && <Styled.LogoLink to="/">{image}</Styled.LogoLink>}
    </Styled.Logo>
  );
};

export default Logo;
