import React from 'react';
import { useAlert } from 'react-alert';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import * as Styled from './TwoFactorAuth.styles';
import { messages } from '../../../../helpers/forms';
import InputWrapper from '../../../../components/InputWrapper';
import Button from '../../../../components/Button';
import { verifyTfa } from '../../../../api/auth';

const TwoFactorAuth = ({ setTfa, currentUser }) => {
  const alert = useAlert();

  return (
    <Styled.TwoFactorAuth>
      <Formik
        initialValues={{
          code: '',
          email: currentUser.email || ''
        }}
        validationSchema={Yup.object({
          code: Yup.string().required(messages.required)
        })}
        onSubmit={values => {
          verifyTfa(values).then(response => {
            if (response.data) {
              setTfa(true);
            } else {
              alert.error('Błędny kod uwierzytelniania');
            }
          });
        }}
      >
        {({ values, errors, touched }) => (
          <Form>
            <legend>Uwierzytelnianie dwuetapowe</legend>
            <InputWrapper
              name="code"
              type="text"
              label="Kod autoryzacyjny"
              errors={errors}
              touched={touched}
              values={values}
            />
            <Button primary large expanded text="Dalej" />
          </Form>
        )}
      </Formik>
    </Styled.TwoFactorAuth>
  );
};

export default TwoFactorAuth;
