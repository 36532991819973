import styled from 'styled-components';

const Users = styled.article`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  > section {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      padding: 10px;

      h2 {
        padding: 5px 5px 0;
      }
    }
  }
`;

export { Users };
