import React from 'react';
import Layout from '../../layouts/Layout/Layout';
import Box from '../../layouts/Box';
import Table from '../../components/Typography/Table';
import * as Styled from './Account.styles';
import AccountEdit from './AccountEdit';
import AccountPasswordChange from './AccountPasswordChange';

const Account = ({ currentUser }) => (
  <Layout title="Konto użytkownika" contentClass="main-user-account">
    <Styled.Account>
      <Box header="Twoje dane">
        <Table>
          <thead>
            <tr>
              <th>Imie i&nbsp;nazwisko</th>
              <th>E-mail</th>
              <th>ID konta</th>
              <th>Typ konta</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{currentUser.name}</td>
              <td>{currentUser.email}</td>
              <td>{currentUser._id}</td>
              <td>Administrator</td>
            </tr>
          </tbody>
        </Table>
      </Box>
      <Box header="Edytuj dane konta" halfLarge>
        <AccountEdit currentUser={currentUser} />
      </Box>
      <Box header="Zmiana hasła" halfLarge>
        <AccountPasswordChange currentUser={currentUser} />
      </Box>
    </Styled.Account>
  </Layout>
);

export default Account;
