import styled from 'styled-components';

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-content: flex-start;
  width: 100%;
  max-width: ${props => props.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background: ${props => props.theme.backgroundColor};

  .view-animation-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;

    .view-animation-enter {
      opacity: 0;
      transform: translateY(5%);
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;

      &.view-animation-enter-active {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .view-animation-exit {
      position: absolute;
      left: 0;
      top: initial;
      right: 0;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;

      &.view-animation-exit-active {
        opacity: 0;
        transform: translateY(5%);
      }
    }
  }

  &.main-user-account {
    display: block;
  }

  &.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

export { Main };
