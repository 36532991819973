import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import * as Styled from './LoginForm.styles';
import { messages } from '../../../../helpers/forms';
import InputWrapper from '../../../../components/InputWrapper';
import Button from '../../../../components/Button';

const LoginForm = ({ onSignIn, setResetPassword }) => {
  const handleSubmit = values => onSignIn(values);
  const handleResetButtonClick = () => setResetPassword(1);

  return (
    <Styled.LoginForm>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(messages.email)
            .required(messages.required),
          password: Yup.string().required(messages.required)
        })}
        onSubmit={(values, { setSubmitting, resetForm }) =>
          handleSubmit(values, setSubmitting, resetForm)
        }
      >
        {({ values, errors, touched }) => (
          <Form>
            <legend>Zaloguj się</legend>
            <InputWrapper
              name="email"
              type="text"
              label="Email"
              errors={errors}
              touched={touched}
              values={values}
            />
            <InputWrapper
              name="password"
              type="password"
              label="Hasło"
              errors={errors}
              touched={touched}
              values={values}
            />
            <Button primary large expanded type="submit" text="Zaloguj się" />
          </Form>
        )}
      </Formik>
    </Styled.LoginForm>
  );
};

export default LoginForm;
