import api from './init';

export const getUsers = () => {
  return api.get('/users').then(res => res.data);
};

export const userUpdate = (id, name) => {
  return api.post(`/users/${id}/update`, { name }).then(res => res.data);
};

export const removeUser = id => {
  return api.delete(`/users/${id}`).then(res => res.data);
};

export const userPasswordChange = (id, oldPassword, newPassword) => {
  return api
    .post(`/users/${id}/password/change`, { oldPassword, newPassword })
    .then(res => res.data)
    .catch(res =>
      res.response.status === 400 ? 'Błędne stare hasło.' : 'Nieznany błąd'
    );
};

export const userPasswordReset = email => {
  return api.post(`/users/password/reset`, { email }).then(res => res.data);
};

export const userPasswordResetValidate = resetCode => {
  return api
    .post(`/users/password/reset/validate`, { resetCode })
    .then(res => res.data);
};
