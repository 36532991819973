import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useAlert } from 'react-alert';
import { useParams, useHistory, Link } from 'react-router-dom';
import Button from '../../components/Button';
import InputWrapper from '../../components/InputWrapper';
import { languages, messages } from '../../helpers/forms';
import Layout from '../../layouts/Layout/Layout';
import * as Styled from './AddPriceList.styles';
import Box from '../../layouts/Box';
import {
  addSinglePriceList,
  getSinglePriceList,
  updateSinglePriceList
} from '../../api/price-lists';
import {
  PriceItem,
  PriceItemsList,
  PriceSubitem,
  PriceItemsSublist
} from './AddPriceList.styles';
import SelectWrapper from '../../components/SelectWrapper';
import Editor from '../../components/Editor';
import RowWrapper from '../../components/RowWrapper/RowWrapper';

const AddPriceList = () => {
  const alert = useAlert();
  const { id } = useParams();
  const history = useHistory();
  const isEdit = id !== undefined;

  const [lang, setLang] = useState('pl');

  const [currentValues, setCurrentValues] = useState({
    title: '',
    rows: [
      {
        name: '',
        price: 0,
        priceType: '',
        desc: '',
        descEn: '',
        items: []
      }
    ]
  });

  useEffect(() => {
    if (isEdit) {
      getSinglePriceList(id)
        .then(response => {
          setCurrentValues({
            title: response.title,
            rows: response.rows
          });
        })
        .catch(error => console.log(error));
    }
  }, []);

  const onCreateEntry = ({ title, rows }, setSubmitting, resetForm) => {
    if (isEdit) {
      updateSinglePriceList(id, { title, rows })
        .then(() => {
          alert.info(`Cennik został zaktualizowany`);
          setSubmitting(false);
          resetForm();
          history.push('/price-lists');
        })
        .catch(error => alert.error(error));
    } else {
      addSinglePriceList({ title, rows })
        .then(() => {
          alert.info(`Dodano nowy cennik`);
          setSubmitting(false);
          resetForm();
          history.push('/price-lists');
        })
        .catch(error => alert.error(error));
    }
  };

  const onAddRow = values =>
    setCurrentValues({
      title: values.title,
      rows: [
        ...values.rows,
        { name: '', price: 0, priceType: '', desc: '', descEn: '', items: [] }
      ]
    });

  const onRemoveRow = index => {
    const tempArray = currentValues.rows;
    delete tempArray[index];

    setCurrentValues({
      title: currentValues.title,
      rows: tempArray
    });
  };

  const onAddRowItem = (rowIndex, values) => {
    const row = values.rows[rowIndex];
    const tempRows = values.rows;

    tempRows[rowIndex] = {
      name: row.name,
      price: row.price,
      priceType: row.priceType,
      desc: row.desc,
      descEn: row.descEn,
      items: [
        ...row.items,
        { name: '', nameEn: '', price: 0, priceType: '', desc: '', descEn: '' }
      ]
    };

    setCurrentValues({
      title: values.title,
      rows: tempRows
    });
  };

  const onRemoveRowItem = (rowIndex, itemIndex) => {
    const tempArray = currentValues.rows;
    delete tempArray[rowIndex].items[itemIndex];

    setCurrentValues({
      title: currentValues.title,
      rows: tempArray
    });
  };

  return (
    <Layout title={isEdit ? 'Aktualizuj cennik' : 'Dodaj cennik'}>
      <Styled.TopPanel>
        <Button as={Link} to="/price-lists" primary>
          &lt; Powrót
        </Button>
        <SelectWrapper
          name="lang"
          handleChange={value => setLang(value)}
          options={languages}
          value={{
            value: lang,
            label: lang === 'pl' ? 'Polski' : 'Angielski'
          }}
          placeholder="Wybierz język"
          isSearchable={false}
        />
      </Styled.TopPanel>

      <Box>
        <Formik
          initialValues={{
            title: currentValues.title,
            rows: currentValues.rows
          }}
          enableReinitialize
          validationSchema={Yup.object({
            title: Yup.string().required(messages.required)
          })}
          onSubmit={(values, { setSubmitting, resetForm }) =>
            onCreateEntry(values, setSubmitting, resetForm)
          }
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form noValidate>
              <InputWrapper
                name="title"
                type="text"
                label="Tytuł cennika"
                errors={errors}
                touched={touched}
                values={values}
              />

              <PriceItemsList>
                {currentValues.rows &&
                  currentValues.rows.map((row, rowIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <PriceItem key={rowIndex}>
                      <InputWrapper
                        name={`rows[${rowIndex}].name`}
                        type="text"
                        label="Nazwa wiersza"
                        errors={errors}
                        touched={touched}
                        values={values}
                      />

                      <RowWrapper>
                        <InputWrapper
                          name={`rows[${rowIndex}].price`}
                          type="number"
                          label="Cena"
                          errors={errors}
                          touched={touched}
                          values={values}
                        />

                        <InputWrapper
                          name={`rows[${rowIndex}].priceType`}
                          label="Typ ceny"
                          as="select"
                          errors={errors}
                          touched={touched}
                          values={values}
                        >
                          <option value="pln">PLN</option>
                          <option value="pln/h">PLN/h</option>
                          <option value="hidden">Ukryj cenę</option>
                        </InputWrapper>
                      </RowWrapper>

                      {lang === 'pl' && (
                        <Editor
                          label="Opis wiersza"
                          data={
                            values.rows[rowIndex] && values.rows[rowIndex].desc
                          }
                          onChange={value =>
                            setFieldValue(`rows[${rowIndex}].desc`, value)
                          }
                          id={`desc-${rowIndex}`}
                        />
                      )}
                      {lang === 'en' && (
                        <Editor
                          label="Opis wiersza"
                          data={
                            values.rows[rowIndex] &&
                            values.rows[rowIndex].descEn
                          }
                          onChange={value =>
                            setFieldValue(`rows[${rowIndex}].descEn`, value)
                          }
                          id={`descen-${rowIndex}`}
                        />
                      )}
                      <Button
                        remove
                        type="button"
                        onClick={() => onRemoveRow(rowIndex)}
                      />

                      <PriceItemsSublist>
                        {currentValues.rows[rowIndex].items &&
                          currentValues.rows[rowIndex].items.map(
                            (item, itemIndex) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <PriceSubitem key={itemIndex}>
                                {lang === 'pl' && (
                                  <InputWrapper
                                    name={`rows[${rowIndex}].items[${itemIndex}].name`}
                                    type="text"
                                    label="Nazwa pozycji"
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                  />
                                )}

                                {lang === 'en' && (
                                  <InputWrapper
                                    name={`rows[${rowIndex}].items[${itemIndex}].nameEn`}
                                    type="text"
                                    label="Nazwa pozycji"
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                  />
                                )}

                                <RowWrapper>
                                  <InputWrapper
                                    name={`rows[${rowIndex}].items[${itemIndex}].price`}
                                    type="number"
                                    label="Cena pozycji"
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                  />

                                  <InputWrapper
                                    name={`rows[${rowIndex}].items[${itemIndex}].priceType`}
                                    label="Typ ceny"
                                    as="select"
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                  >
                                    <option value="pln">PLN</option>
                                    <option value="pln/h">PLN/h</option>
                                    <option value="hidden">Ukryj cenę</option>
                                  </InputWrapper>
                                </RowWrapper>

                                {lang === 'pl' && (
                                  <Editor
                                    label="Opis pozycji"
                                    data={
                                      values.rows[rowIndex] &&
                                      values.rows[rowIndex].items[itemIndex] &&
                                      values.rows[rowIndex].items[itemIndex]
                                        .desc
                                    }
                                    onChange={value =>
                                      setFieldValue(
                                        `rows[${rowIndex}].items[${itemIndex}].desc`,
                                        value
                                      )
                                    }
                                    id={`desc-${rowIndex}-${itemIndex}`}
                                  />
                                )}
                                {lang === 'en' && (
                                  <Editor
                                    label="Opis pozycji"
                                    data={
                                      values.rows[rowIndex] &&
                                      values.rows[rowIndex].items[itemIndex] &&
                                      values.rows[rowIndex].items[itemIndex]
                                        .descEn
                                    }
                                    onChange={value =>
                                      setFieldValue(
                                        `rows[${rowIndex}].items[${itemIndex}].descEn`,
                                        value
                                      )
                                    }
                                    id={`descen-${rowIndex}-${itemIndex}`}
                                  />
                                )}
                                <Button
                                  remove
                                  type="button"
                                  onClick={() =>
                                    onRemoveRowItem(rowIndex, itemIndex)
                                  }
                                />
                              </PriceSubitem>
                            )
                          )}
                      </PriceItemsSublist>
                      <Button
                        type="button"
                        onClick={() => onAddRowItem(rowIndex, values)}
                      >
                        Dodaj pozycję wewnątrz wiersza
                      </Button>
                    </PriceItem>
                  ))}
              </PriceItemsList>

              <Button type="button" onClick={() => onAddRow(values)}>
                Dodaj kolejny wiersz
              </Button>

              <Button type="submit" success>
                {isEdit ? 'Aktualizuj' : 'Zapisz'}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Layout>
  );
};

export default AddPriceList;
