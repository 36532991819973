import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Layout from '../../layouts/Layout/Layout';
import * as Styled from './Clients.styles';
import TableContentLoader from '../../components/ContentLoader/TableContentLoader';
import ClientsTable from './ClientsTable';
import Box from '../../layouts/Box';
import { BoxHeader } from '../../layouts/Box/Box.styles';
import Button from '../../components/Button';
import { getClients } from '../../api/client';

const Clients = () => {
  const alert = useAlert();
  const [clientsData, setClientsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getClients()
      .then(response => {
        setClientsData(response);
        setLoading(false);
      })
      .catch(error => {
        alert.error('Błąd ładowania klientów');
        console.error(error);
      });
  }, []);

  return (
    <Layout title="Klienci">
      <Styled.Clients>
        <Box>
          <BoxHeader>
            <h3>Lista klientów</h3>
            <Button type="button" as={Link} to="/clients/add" small primary>
              Dodaj klienta
            </Button>
          </BoxHeader>
          {loading && <TableContentLoader />}
          {!loading && clientsData.length === 0 && <p>Brak klientów</p>}
          {!loading && clientsData.length !== 0 && (
            <ClientsTable
              clientsData={clientsData}
              setClientsData={setClientsData}
            />
          )}
        </Box>
      </Styled.Clients>
    </Layout>
  );
};

export default Clients;
