import styled from 'styled-components';

const SignIn = styled.div`
  background: radial-gradient(at center, #3498db, #9b59b6);
  background-size: 200% 200%;
  height: 100vh;
  width: 100vw;

  @keyframes gradientAnimation {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

const SignInFormWrapper = styled.section`
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  max-width: 1024px;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.2);

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
  }

  .signin-aside {
    position: relative;
    padding: 40px;
    color: ${props => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      width: 30%;
    }

    .signin-logo-wrapper {
      @media (max-width: ${props => props.theme.breakpoints.lg}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    h2 {
      margin-top: 20px;
      font-size: 24px;
      color: ${props => props.theme.colors.white};
    }

    img {
      width: 120px;
      margin-bottom: 10px;
    }
  }

  .signin-main {
    flex-grow: 1;
    padding: 15px;
    background-color: ${props => props.theme.colors.white};

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      padding: 80px;
    }

    form {
      legend {
        margin-bottom: 20px;
        font-size: 20px;
      }
    }
  }

  @keyframes waves {
    0%,
    100% {
    }

    50% {
    }
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
  }
`;

export { SignIn, SignInFormWrapper };
