/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ReactAlert from './components/ReactAlert';
import themes from './config/theme';
import GlobalStyle from './GlobalStyle';

const Root = () => (
  <ThemeProvider theme={themes.base}>
    <GlobalStyle />
    <ReactAlert>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReactAlert>
  </ThemeProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
registerServiceWorker();
