import React from 'react';
import * as Styled from './Switch.styles';

const SwitchWrapper = ({ value, handleChange }) => {
  const onChange = () => {
    handleChange(!value);
  };

  return (
    <Styled.SwitchWrapper isActive={value} onClick={onChange}>
      <Styled.Circle isActive={value} />
    </Styled.SwitchWrapper>
  );
};

export default SwitchWrapper;
