import styled from 'styled-components';

const PageHeader = styled.header`
  display: flex;
  width: 100%;

  h2 {
    margin-bottom: 20px;
  }
`;

export { PageHeader };
