import styled from 'styled-components';
import { NavLink as NativeNavLink } from 'react-router-dom';

const Logo = styled.h1`
  padding: 0;
`;

const LogoPartner = styled.span`
  padding: 0;
`;

const LogoLink = styled(NativeNavLink)`
  display: block;
`;

export { Logo, LogoPartner, LogoLink };
