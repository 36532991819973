import styled from 'styled-components';

const ReactAlert = styled.div`
  margin: 0;
  overflow: initial;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.colors.white};
  box-shadow: 2px 1px 15px 1px rgba(33, 37, 41, 0.43);

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 310px;
  }
  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    min-width: 310px;
  }

  .alert-icon-box {
    display: flex;
    justify-content: center;
    align-content: center;
    border-top-left-radius: ${props => props.theme.borderRadius};
    border-top-right-radius: ${props => props.theme.borderRadius};

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      padding: 20px 10px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      padding: 30px 10px;
    }
  }

  .alert-icon-box-info {
    background-color: ${props => props.theme.colors.primary};
  }

  .alert-icon-box-success {
    background-color: ${props => props.theme.colors.success};
  }

  .alert-icon-box-error {
    background-color: ${props => props.theme.colors.alert};
  }

  .alert-icon {
    fill: ${props => props.theme.colors.white};
    display: block;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      width: 35px;
      height: 35px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      width: 50px;
      height: 50px;
    }
  }

  .alert-msg-box {
    padding: 40px 10px 10px;
    margin-bottom: 0;
    text-align: center;
    white-space: pre-line;
  }
`;

const AlertBtnBox = styled.div`
  text-align: center;

  button {
    min-width: 100px;
    margin: 0 10px;
    transform: translateY(50%);
    line-height: 1;
  }
`;

export { ReactAlert, AlertBtnBox };
