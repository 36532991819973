import styled, { css } from 'styled-components';

const Box = styled.section`
  position: relative;
  width: 100%;
  flex: 1 100%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius};

  h3 {
    display: inline-flex;
    margin-bottom: 20px;
  }

  ${props =>
    props.overflow &&
    css`
      overflow: hidden;
    `}

  ${props =>
    props.halfLarge &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        flex: 0 calc(50% - 10px);
      }
    `}
`;

const BoxHeader = styled.header`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  h3 {
    margin: 0 0 10px;
  }
`;

export { Box, BoxHeader };
