import styled from 'styled-components';

const Account = styled.article`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  > section {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      padding: 10px;

      h2 {
        padding: 5px 5px 0;
      }
    }

    > table {
      @media (max-width: ${props => props.theme.breakpoints.lg}) {
        display: flex;

        thead,
        tbody {
          tr {
            display: flex;
            flex-direction: column;
          }
          td,
          th {
            border-right: none;
            display: flex;
            align-items: center;
            min-height: 80px;
            word-wrap: break-word;

            @media (max-width: ${props => props.theme.breakpoints.lg}) {
              padding: 20px 10px;
            }
          }
        }

        thead {
          width: max-content;
        }

        tbody {
          width: 100%;
        }
      }
    }
  }
`;

export { Account };
