import api from './init';

const getClients = data => {
  return api
    .get('/clients', {
      params: data
    })
    .then(res => res.data);
};

const getClient = id => {
  return api.get(`/clients/${id}`).then(res => res.data);
};

const removeClient = id => {
  return api.delete(`/clients/${id}`).then(res => res.data);
};

const addClient = data => {
  return api.post(`/clients/add`, data).then(res => res.data);
};

const updateClient = (id, data) => {
  return api.post(`/clients/${id}/update`, data).then(res => res.data);
};

export { getClients, getClient, removeClient, addClient, updateClient };
