import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import Layout from '../../layouts/Layout/Layout';
import * as Styled from './Submissions.styles';
import TableContentLoader from '../../components/ContentLoader/TableContentLoader';
import Box from '../../layouts/Box';
import { BoxHeader } from '../../layouts/Box/Box.styles';
import { getSubmissions } from '../../api/submissions';
import SubmissionsTable from './SubmissionsTable';

const Submissions = () => {
  const alert = useAlert();
  const [submissionsData, setSubmissionsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSubmissions()
      .then(response => {
        setSubmissionsData(response);
        setLoading(false);
      })
      .catch(error => {
        alert.error('Błąd ładowania zgłoszeń');
        console.error(error);
      });
  }, []);

  return (
    <Layout title="Zgłoszenia z formularza">
      <Styled.Submissions>
        <Box>
          <BoxHeader>
            <h3>Lista zgłoszeń</h3>
          </BoxHeader>
          {loading && <TableContentLoader />}
          {!loading && submissionsData.length === 0 && <p>Brak zgłoszeń</p>}
          {!loading && submissionsData.length !== 0 && (
            <SubmissionsTable
              submissionsData={submissionsData}
              setSubmissionsData={setSubmissionsData}
            />
          )}
        </Box>
      </Styled.Submissions>
    </Layout>
  );
};

export default Submissions;
