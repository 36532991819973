import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Layout from '../../layouts/Layout/Layout';
import * as Styled from './PriceLists.styles';
import TableContentLoader from '../../components/ContentLoader/TableContentLoader';
import PriceListsTable from './PriceListsTable';
import Box from '../../layouts/Box';
import { BoxHeader } from '../../layouts/Box/Box.styles';

import { getPriceLists } from '../../api/price-lists';
import Button from '../../components/Button';

const PriceLists = () => {
  const alert = useAlert();
  const [priceListsData, setPriceListsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPriceLists()
      .then(response => {
        setPriceListsData(response);
        setLoading(false);
      })
      .catch(error => {
        alert.error('Błąd ładowania cenników');
        console.error(error);
      });
  }, []);

  return (
    <Layout title="Cenniki">
      <Styled.PriceLists>
        <Box>
          <BoxHeader>
            <h3>Cenniki</h3>
            <Button type="button" as={Link} to="/price-lists/add" small primary>
              Dodaj cennik
            </Button>
          </BoxHeader>
          {loading && <TableContentLoader />}
          {!loading && priceListsData.length === 0 && <p>Brak cenników</p>}
          {!loading && priceListsData.length !== 0 && (
            <PriceListsTable
              priceListsData={priceListsData}
              setPriceListsData={setPriceListsData}
            />
          )}
        </Box>
      </Styled.PriceLists>
    </Layout>
  );
};

export default PriceLists;
