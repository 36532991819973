import React from 'react';
import * as Styled from './Header.styles';
import Logo from '../Logo';
import NavToggler from '../Navigation/NavToggler';

const Header = ({ navToggler }) => (
  <Styled.Header>
    <Styled.LogoWrapper>
      <Logo anchor />
      <Logo type="client" />
    </Styled.LogoWrapper>
    <NavToggler navToggler={navToggler} />
  </Styled.Header>
);

export default Header;
