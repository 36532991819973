/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactQuill from 'react-quill';
import * as Styled from './Editor.styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-quill/dist/quill.snow.css';

const Editor = ({ label, data, onChange: getChange, id, className }) => (
  <Styled.Editor>
    <Styled.Label>{label}</Styled.Label>
    {console.log(data)}
    <div id={`toolbar-${id}`} className={className}>
      <select className="ql-header" defaultValue="normal">
        <option value="2" />
        <option value="3" />
        <option value="4" />
        <option value="5" />
        <option value="normal" />
      </select>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <button className="ql-blockquote" />
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-direction" />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
      <button className="ql-link" />
      <button className="ql-image" />
      <button className="ql-clean" />
      <select className="ql-align" />
    </div>
    <ReactQuill
      style={{ minHeight: '148px' }}
      onChange={value => {
        getChange(value);
      }}
      value={data}
      theme="snow"
      modules={{ toolbar: `#toolbar-${id}` }}
    />
  </Styled.Editor>
);

export default Editor;
