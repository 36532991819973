import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: ${props => props.theme.colors.white};
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      width: 60px;
      margin-right: 15px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      width: 90px;
      margin-right: 30px;
    }
  }
`;

export { Header, LogoWrapper };
