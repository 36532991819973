import styled from 'styled-components';

const Table = styled.table`
  //overflow: hidden;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius};
  transform: translateZ(0);

  caption {
    margin-bottom: 20px;
    font-size: 24px;
  }

  thead {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};

    th {
      color: ${props => props.theme.colors.white};

      &.table__cell--header {
        .icon {
          display: none;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid #306ca0;
      }
    }
  }

  tbody {
    td,
    th {
      &:not(:last-child) {
        border-right: 1px solid #eee;
      }
    }
  }

  td,
  th {
    font-size: 16px;
    line-height: 1.2;
    padding: 8px;
  }

  td > a,
  td > button {
    vertical-align: middle;
  }

  tbody tr {
    &:nth-of-type(odd) {
      background-color: ${props => props.theme.colors.lightGray};
    }
    &:nth-of-type(even) {
    }
    &:hover {
      background-color: ${props => props.theme.colors.darkenGray};
    }
  }

  .room-name {
    white-space: nowrap;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  margin-bottom: 30px;
`;

export { Table, TableWrapper };
