import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Table from '../../components/Typography/Table';
import Button from '../../components/Button';
import * as Styled from '../../components/Typography/Table/Table.styles';
import { removeArticle, updateArticlePublished } from '../../api/article';
import Switch from '../../components/Switch/Switch';
import Icon from '../../components/Icon/Icon';
import { renderDate } from '../../helpers/date';

const ArticlesTable = ({ articlesData, setArticlesData }) => {
  const alert = useAlert();
  const [sortType, setSortType] = useState('addedDateUp');

  const sortedArticles = useMemo(() => {
    switch (sortType) {
      case 'titleUp': {
        return articlesData.sort((a, b) => a.title.localeCompare(b.title));
      }
      case 'titleDown': {
        return articlesData.sort((a, b) => b.title.localeCompare(a.title));
      }
      case 'categoryUp': {
        return articlesData.sort(a => (a.category === 'articles' ? 1 : -1));
      }
      case 'categoryDown': {
        return articlesData.sort(a => (a.category === 'podcasts' ? 1 : -1));
      }
      case 'externalUp': {
        return articlesData.sort(a => (!a.external ? 1 : -1));
      }
      case 'externalDown': {
        return articlesData.sort(a => (a.external ? 1 : -1));
      }
      case 'publishedUp': {
        return articlesData.sort(a => (a.published ? 1 : -1));
      }
      case 'publishedDown': {
        return articlesData.sort(a => (!a.published ? 1 : -1));
      }
      case 'addedDateUp': {
        return articlesData.sort(
          (a, b) => new Date(b.addedDate) - new Date(a.addedDate)
        );
      }
      case 'addedDateDown': {
        console.log('selected  addedDateDown');
        return articlesData.sort(
          (a, b) => new Date(a.addedDate) - new Date(b.addedDate)
        );
      }
      default: {
        return articlesData;
      }
    }
  }, [sortType, articlesData]);

  const renderArrows = sortName => {
    if (sortType.includes(sortName)) {
      return (
        <span>
          {sortType.includes('Up') ? (
            <Icon icon="sort-asc" size={12} />
          ) : (
            <Icon icon="sort-desc" size={12} />
          )}
        </span>
      );
    }

    return null;
  };

  const changeSortType = sortName => {
    setSortType(prevType =>
      prevType === `${sortName}Up` ? `${sortName}Down` : `${sortName}Up`
    );
  };

  const onRemove = id =>
    removeArticle(id)
      .then(() =>
        setArticlesData(
          articlesData.filter(application => application._id !== id)
        )
      )
      .catch(error => alert.error('Wystąpił błąd', error));

  const onPublishedChange = (published, id) =>
    updateArticlePublished(id, { published })
      .then(() =>
        setArticlesData(
          articlesData.filter(article => {
            if (article._id === id) {
              const temp = article;
              temp.published = published;
              return temp;
            }
            return article;
          })
        )
      )
      .catch(error => console.log(error));

  return (
    <Styled.TableWrapper>
      <Table>
        <thead>
          <tr>
            <th onClick={() => changeSortType('title')}>
              Tytuł {renderArrows('title')}
            </th>
            <th onClick={() => changeSortType('category')}>
              Kategoria {renderArrows('category')}
            </th>
            <th onClick={() => changeSortType('external')}>
              Rodzaj artykułu {renderArrows('external')}
            </th>
            <th onClick={() => changeSortType('addedDate')}>
              Data dodania {renderArrows('addedDate')}
            </th>
            <th>Autor</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th onClick={() => changeSortType('published')}>
              Opublikowane {renderArrows('published')}
            </th>
          </tr>
        </thead>
        <tbody>
          {articlesData &&
            sortedArticles.map(row => (
              <tr key={row._id}>
                <td>
                  <Link to={`/articles/edit/${row._id}`}>{row.title}</Link>
                </td>
                <td>{row.category === 'articles' ? 'Artykuły' : 'Podcasty'}</td>
                <td>{row.external ? 'Zewnętrzny' : 'Wewnętrzny'}</td>
                <td>{renderDate(row.addedDate)}</td>
                <td>{row.author}</td>
                <td>
                  <Button
                    type="button"
                    small
                    primary
                    as={Link}
                    to={`/articles/edit/${row._id}`}
                  >
                    Edytuj
                  </Button>
                </td>
                <td>
                  <Button
                    danger
                    onlyIcon
                    small
                    onClick={() =>
                      // eslint-disable-next-line no-undef,no-alert
                      window.confirm('Na pewno chcesz usunąć atrykuł?') &&
                      onRemove(row._id)
                    }
                  >
                    Usuń
                  </Button>
                </td>
                <td>
                  {!row.external ? (
                    <Button
                      type="button"
                      small
                      success
                      as="a"
                      target="_blank"
                      href={`${process.env.REACT_APP_WEBSITE_URL}/preview/${row._id}`}
                      rel="noreferrer"
                    >
                      Podgląd
                    </Button>
                  ) : (
                    <b>N / A</b>
                  )}
                </td>
                <td>
                  <Switch
                    value={row.published}
                    handleChange={value => onPublishedChange(value, row._id)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Styled.TableWrapper>
  );
};

export default ArticlesTable;
