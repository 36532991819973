import React from 'react';
import { Field } from 'formik';
import * as Styled from './InputWrapper.styles';

const InputWrapper = ({
  className,
  name,
  type,
  as,
  label,
  errors,
  touched,
  values,
  children,
  component,
  disabled,
  autocomplete
}) => (
  <Styled.InputWrapper
    className={`${className || ''} ${
      errors[name] && touched[name] ? ' filed-error' : ''
    }${
      !errors[name] &&
      touched[name] &&
      values[name] !== false &&
      values[name] !== ''
        ? ' filed-success'
        : ''
    }`}
  >
    <Field
      name={name}
      id={name}
      type={type}
      as={as}
      placeholder={name}
      component={component}
      disabled={disabled}
      autoComplete={autocomplete}
    >
      {children}
    </Field>
    {label && (
      <Styled.FieldLabel htmlFor={name}>
        <span>{label}</span>
      </Styled.FieldLabel>
    )}
    {errors[name] && touched[name] && (
      <Styled.FieldMsg role="alert">{errors[name]}</Styled.FieldMsg>
    )}
  </Styled.InputWrapper>
);

export default InputWrapper;
