import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: flex-start;
`;

export { Layout };
