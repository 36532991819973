const renderNumber = num => (num < 10 ? `0${num}` : num);

export const renderDate = date => {
  const newDate = new Date(date);

  return `${renderNumber(newDate.getDate())}/${renderNumber(
    newDate.getMonth() + 1
  )}/${newDate.getFullYear()}, 
  ${renderNumber(newDate.getHours())}:${renderNumber(newDate.getMinutes())}`;
};
