import styled from 'styled-components';

const SelectWrapper = styled.div`
  min-width: 150px;
  font: 13px/1 'Open Sans';

  .select-wrapper__control {
    border: 2px solid ${props => props.theme.colors.darkenGray};
    border-radius: 10px;
    transition: 0.2s border-color;
    box-shadow: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &:focus-within {
      border-color: ${props => props.theme.colors.primary};
    }

    &.select-wrapper__control--menu-is-open {
      border-color: ${props => props.theme.colors.primary};
      border-bottom: none;
      border-radius: 10px 10px 0 0;

      .select-wrapper__indicator {
        transform: rotate(-180deg);
      }
    }
  }

  .select-wrapper__placeholder {
    color: ${props => props.theme.fontColor};
  }

  .select-wrapper__indicator-separator {
    display: none;
  }

  .select-wrapper__indicator {
    color: ${props => props.theme.fontColor};
    transition: 0.2s;

    &:hover {
      color: ${props => props.theme.fontColor};
    }
  }

  .select-wrapper__menu {
    margin: 0;
    border: 2px solid ${props => props.theme.colors.primary};
    border-top: none;
    border-radius: 0 0 10px 10px;
    box-shadow: none;
    transition: 0.2s;

    .select-wrapper__menu-list {
      padding: 0;

      .select-wrapper__option {
        &.select-wrapper__option--is-selected {
          background: ${props => props.theme.colors.primary};
        }

        &:last-child {
          border-radius: 0 0 10px 10px;
        }
      }
    }
  }
`;

export { SelectWrapper };
