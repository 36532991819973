import React from 'react';
import { Formik, Form } from 'formik';
import { useAlert } from 'react-alert';
import Button from '../../components/Button';
import InputWrapper from '../../components/InputWrapper';
import { userUpdate } from '../../api/users';

const AccountEdit = ({ currentUser }) => {
  const alert = useAlert();

  const onUpdateUser = name => {
    userUpdate(currentUser._id, name)
      .then(response => {
        alert.info(
          `Dane konta zostały zaktualizowane: ${response.data}. Zaloguj się ponownie.`
        );
      })
      .catch(error => console.error('Error loading data', error));
  };

  return (
    <Formik
      initialValues={{
        name: currentUser.name,
        email: currentUser.email
      }}
      onSubmit={values => {
        onUpdateUser(values.name);
        currentUser.logout();
      }}
    >
      {({ values, errors, touched }) => (
        <Form noValidate>
          <InputWrapper
            name="name"
            type="text"
            label="Imię i nazwisko"
            errors={errors}
            touched={touched}
            values={values}
          />
          <InputWrapper
            name="email"
            type="email"
            label="Adres e-mail"
            errors={errors}
            touched={touched}
            values={values}
            disabled
          />
          <Button primary>Zapisz</Button>
        </Form>
      )}
    </Formik>
  );
};

export default AccountEdit;
