import api from './init';

export const getPriceLists = () => {
  return api.get('/price-lists').then(res => res.data);
};

export const getSinglePriceList = id => {
  return api.get(`/price-lists/${id}`).then(res => res.data);
};

export const removeSinglePriceList = id => {
  return api.delete(`/price-lists/${id}`).then(res => res.data);
};

export const addSinglePriceList = data => {
  return api.post(`/price-lists/add`, data).then(res => res.data);
};

export const updateSinglePriceList = (id, data) => {
  return api.post(`/price-lists/${id}/update`, data).then(res => res.data);
};
