import React from 'react';
import * as Styled from './Footer.styles';
import packageJson from '../../../package.json';

const Footer = () => (
  <Styled.Footer>
    &copy;{' '}
    <a href="https://plej.pl/" target="_blank" rel="noreferrer noopener">
      Plej.pl
    </a>{' '}
    | wersja: {packageJson.version}
  </Styled.Footer>
);

export default Footer;
